window.addEventListener('click', (event) => {

    // Clear group of filters
    const target = event.target;
    const form = target.closest('[data-ajax-filters]');

    if (form) {
        const dataset = target.dataset.clearFilterGroup;

        if (dataset) {
            const data = JSON.parse(dataset);

            if (data) {
                const list = document.getElementById(data.list);
                const name = data.name;

                if (list && name) {
                    const checkboxes = [...list.querySelectorAll(`[name="${name}"]:checked`)];

                    if (checkboxes) {
                        checkboxes.forEach(checkbox => {
                            checkbox.checked = false;
                        });

                        const formName = form.dataset.form || 'unknown';
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'filter_clear',
                            filter_form: formName,
                            filter_group: name,
                            page_location: window.location.pathname
                        });

                        form.querySelector('button[type=submit]').click();
                    }
                }
            }
        }
    }

    // Toggle filters (mobile)
    if (target.dataset.toggleFilters) {
        const filterId = target.dataset.toggleFilters;
        const filterElement = document.getElementById(filterId);

        filterElement.classList.toggle('is-open');

        const isOpen = filterElement.classList.contains('is-open');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'filter_toggle',
            filter_container: filterId,
            filter_action: isOpen ? 'open' : 'close',
            page_location: window.location.pathname
        });
    }
});

const INIT_ajaxFilters = () => {
    window.addEventListener('popstate', () => {
        window.location = location.href;
    });

    // Submit listener
    window.addEventListener('submit', (event) => {
        const target = event.target;
        const form = target.closest('[data-ajax-filters]');

        if (form) {
            event.preventDefault();
            fetchFilteredData(form);
        }
    });

    // Submit on change
    window.addEventListener('change', (event) => {
        const target = event.target;
        const form = target.closest('[data-ajax-filters]');

        if (form) {
            const btnSubmit = form.querySelector('button[type=submit]');

            if (window.getComputedStyle(btnSubmit).display === 'none') {
                fetchFilteredData(form);
            }
        }
    });
}

async function fetchFilteredData(form) {
    const blockName = form.dataset.form;
    const targetSelector = `[data-content="${blockName}"]`;

    document.querySelector(targetSelector).classList.add('is-loading');

    const data = new FormData(form);

    const formName = form.dataset.form || 'unknown';

    const activeFilters = {};
    let totalFiltersApplied = 0;

    for (const [key, value] of data.entries()) {
        if (value) {
            if (!activeFilters[key]) {
                activeFilters[key] = [];
            }
            activeFilters[key].push(value);
            totalFiltersApplied++;
        }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'filter_apply',
        filter_form: formName,
        filter_count: totalFiltersApplied,
        filter_values: JSON.stringify(activeFilters),
        page_location: window.location.pathname
    });

    const query = new URLSearchParams();

    for (const [key, value] of data.entries()) {
        query.append(key, value);
    }

    const response = await fetch(`${form.action}?${query.toString()}`);
    const body = await response.text();
    const div = document.createElement('div');

    div.innerHTML = body;

    document.querySelector(targetSelector).innerHTML = div.querySelector(targetSelector).innerHTML;
    document.querySelector(targetSelector).classList.remove('is-loading');

    window.history.pushState({}, null, response.url);
}

INIT_ajaxFilters();